<template>
    <page-layout-nolo :back="$t('btn.backToOrdering')" :back-levels="2">
        <v-container class="review pt-4 align-left">
            <shop-basket :basket="basket" @input="newBasket" @checkout="checkout" :language="appLanguage" big-buttons
                         :review="true" @edit-item="editItem"
                         :upsells="upsells" @upsells="newUpsells"
                         bottom-checkout
                         :currency="appCurrency"/>

        </v-container>
        <v-dialog :width="isXs ? '' : 800"
                  transition="dialog-bottom-transition"
                  v-model="selectedItemShow">
            <v-layout class="overflow-x-hidden">
                <shop-item-configure v-if="selectedItemShow"
                                     :editing="selectedBasketIndex !== null"
                                     @add-basket="what => addedItem(what)"
                                     @close="selectedItemShow = false"
                                     :item="selectedItem"
                                     :init-qty="selectedQuantity"
                                     :pre-config="selectedPreConfig"
                                     :category-code="selectedCategoryCode"
                                     :flat-menu="appFlatMenu"
                                     :language="appLanguage" :currency="appCurrency"
                />
            </v-layout>
        </v-dialog>

    </page-layout-nolo>
</template>

<script>
    import ShopMixin from "../../mixins/ShopMixin";

    export default {
        name: "ReviewOrder",
        data: function () {
            return {
            }
        },
        mixins: [ShopMixin],
        mounted() {
        },
        methods: {
            checkout() {
                this.$router.push({name: 'checkout'});
            },
        }
    }
</script>

<style>

    .review  .basketCase {
        position: relative;
        top: 0;
        margin: 0;
    }
    .review  .basketContent  {
        max-height: initial !important;
    }

</style>
